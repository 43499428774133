import React from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { HeaderContainer } from './SearchPage/SearchResults';
import { SuperMega, Hero } from './ui/Headings';
import { ButtonLink } from './ui/Button';
import { theme } from './Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const ErrorWrapper = styled('div')`
  margin: 0 auto;

  ${theme.above.sm} {
    max-width: 740px;
  }
`;

const Title = styled(SuperMega)`
  margin-bottom: 15px;
`;

const SubTitle = styled(Hero)`
  margin-bottom: 30px;
`;

const ErrorBody = styled('div')`
  font-family: ${theme.fonts.primary};
  ${theme.fontSizes.regular}
`;

const P = styled('p')`
  margin-bottom: 10px;

  strong {
    font-weight: bold;
  }
`;

const Link = styled(ButtonLink)`
  width: auto;
  max-width: 200px;
  margin-top: 30px;
`;

/*==============================================================================
  # 
==============================================================================*/

const NotFoundPage = () => {
  return (
    <HeaderContainer>
      <ErrorWrapper>
        <Title size="h1">404</Title>

        <SubTitle>
          {t(
            `You have attempted to download a page that does not exist on Jetshop's server.`
          )}
        </SubTitle>

        <ErrorBody>
          <P>
            <strong>{t('This could be due to the following:')}</strong>
          </P>
          <P>
            {t(
              'The address provided is incorrect. Please check the spelling and try again.'
            )}
          </P>
          <P>
            {t(
              'You are trying to use an old address that no longer exists or that may have changed.'
            )}
          </P>
          <P>
            {t(
              'You may have used an old bookmark. If you are sure that you have used the correct address to the site, the problem may be caused by a temporary service disruption. Please try again later.'
            )}
          </P>
          <P>
            {t(
              'NOTE! If you were directed here via a link we are grateful if you inform us as to which link it was so that we can resolve the problem.'
            )}
          </P>
        </ErrorBody>

        <Link to="/" dark={true}>
          Hem
        </Link>
      </ErrorWrapper>
    </HeaderContainer>
  );
};

export default NotFoundPage;
